.mapContainer {
  width: 100vw;
  margin: 10px auto;
  border: 0;
  min-height: 200px;
  height: 80vw;
  max-width: 600px;
  max-height: 350px;
}
.map {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .mapContainer {
    max-width: 900px;
    max-height: 500px;
  }
}
@media (min-width: 1200px) {
  .mapContainer {
    max-width: 1400px;
    max-height: 600px;
  }
}
