.container {
  width: 100%;
  max-width: 600px;
  margin: 0px auto;
  background: linear-gradient(to bottom, #5a5a5a, rgb(190, 190, 190));
  padding: 30px 0px;
}
.carPhoto {
  height: 33vw;
  min-height: 200px;

  max-height: 400px;
  width: 100%;
  /* height: 100%; */
  transition: 1s;
  object-fit: cover;
}

.title {
  font-size: 26px;
  max-width: 300px;
  color: #ffffff;
  margin: 0px auto 30px;
  text-align: center;
}
.sliderInnerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 600px;
  min-height: 250px;
  max-height: 400px;
  object-fit: cover;
  margin: 0px auto;
}
.sliderWrapper {
  max-width: 600px;
  margin: 0px auto;
  position: relative;
}
.soldWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 0px;
  max-width: 400px;
  background-color: rgba(255, 255, 255, 0.4);
}
.soldImg {
  position: absolute;
  width: 150px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -100%) rotateZ(-15deg);
}
.carName {
  font-size: 25px;
  text-align: center;
  margin: 10px auto;
}
.carPrice {
  font-size: 22px;
  margin-left: 40px;
}
.innerWrapper {
  width: 80%;
  display: flex;
  position: relative;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 550px;
  margin: 10px 20px;
}
.iconsContainer {
  height: 30px;
  width: 70px;
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.icon {
  height: 20px;
}
.outWrapper {
  margin: 40px 10px;
  background-color: #ffffff;
  position: relative;
  padding: 20px 10px;
  border-radius: 10px;
}
.hitLogo {
  position: absolute;
  width: 80px;
  top: 0;
  left: 0;
  z-index: 2;
  transform: rotateZ(-35deg);
}
.averagePrice {
  width: 100%;
  height: 30px;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 0px auto 15px;
  justify-content: center;
  text-align: center;
}
.averagePrice > p {
  font-size: 22px;
  font-weight: bold;
}
.avtoriaLogo {
  height: 25px;
  width: 75px;
  margin: 0px 10px;
}
.prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 70%;
  display: block;
  width: 35px;
  height: 35px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  left: 10px;
  background: transparent;
  z-index: 10;
}
.next {
  right: 10px;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 70%;
  display: block;
  width: 35px;
  height: 35px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  /* left: 10px; */
  background: transparent;
  z-index: 10;
}

.prev::before,
.next::before {
  font-family: "slick";
  content: "←";
  width: 100%;
  height: 100%;

  font-size: 35px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  transition: 200ms;
}
.next::before {
  content: "→";
}
.next:hover::before,
.prev:hover:before {
  opacity: 1;
  font-size: 37px;
  transition: 200ms;
}
.textContainer {
  width: 100%;
}
.zoomSvg {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  z-index: 20;
}
.zoomSvg:hover {
  cursor: pointer;
}
@media (min-width: 768px) {
  .container {
    max-width: 900px;
  }
  .soldWrapper {
    max-width: 800px;
  }
  .soldImg {
    width: 350px;
    top: 60%;
  }
  .carName {
    font-size: 35px;
    margin: 30px auto;
  }
  .sliderWrapper {
    max-width: 800px;
  }
  .iconText {
    font-size: 28px;
    margin-top: 10px;
  }
  .iconsContainer {
    width: 130px;
  }
  .sliderInnerWrapper {
    max-width: 800px;
    max-height: 500px;
  }
  .carPhoto {
    max-width: 800px;
    max-height: 500px;
    height: 65vw;
  }
  .carPrice {
    font-size: 30px;
  }
  .icon {
    height: 35px;
  }
  .next::before,
  .prev::before {
    font-size: 70px;
  }
  .next:hover::before,
  .prev:hover:before {
    font-size: 75px;
  }
  .next,
  .prev {
    width: 70px;
    height: 70px;
  }
  .hitLogo {
    width: 130px;
  }
  .zoomSvg {
    width: 70px;
  }
  .averagePrice {
    font-size: 35px;
    margin: 25px auto;
  }
  .avtoriaLogo {
    width: 120px;
    height: 35px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1400px;
  }
  .sliderWrapper {
    width: 600px;
    margin: 20px;
    max-width: 600px;
  }
  .textContainer {
    width: 500px;
  }

  .outWrapper {
    display: flex;
    margin: 40px 20px;
    flex-wrap: wrap;
  }
  .hitLogo {
    width: 130px;
  }
  .carName {
    font-size: 34px;
  }
  .carPrice {
    margin: 30px 0px 30px 40px;
    font-size: 32px;
  }

  .iconsContainer {
    width: 100px;
    margin: 10px 20px;
  }
  .iconsContainer > p {
    font-size: 24px;
  }
  .icon {
    height: 30px;
  }
  .averagePrice {
    margin-top: 40px;
    font-size: 24px;
  }
  .title {
    font-size: 38px;
  }
}
