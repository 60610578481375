.deliveryContainer,
.priceContainer {
  width: 100%;
  display: flex;
  max-width: 600px;

  margin: 00px auto;
  padding-top: 20px;
  flex-wrap: wrap;
  justify-content: center;
}
.deliveryContainer {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  position: relative;
  background-image: url(../../assets//img/255308.jpg);
  background-attachment: fixed;
}
.shadow {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
}
.title {
  font-size: 17px;
  font-weight: bold;
  align-self: center;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  line-height: 25px;
  color: #ffffff;
}
.mapImg {
  width: 90%;
}
.mapImgWrapper {
  width: 90%;
  margin: 30px auto;
  max-width: 530px;
}
.priceInput {
  width: 100%;
  max-width: 400px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  height: 38px;
  padding: 2px 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  margin: 15px auto;
}
.departWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.priceWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.span {
  width: 100%;
  font-size: 12px;
  font-weight: normal;
  line-height: 22px;
  font-family: "Montserrat", sans-serif;
  display: flex;
  color: #ffffff;

  justify-content: space-between;
}
.innerSpan {
  font-weight: bold;
  color: #ffffff;
}
.totalDeliveryCostWrappe {
  margin-right: auto;
  margin-left: 0px;
  width: 100%;
}
.totalDeliveryCost {
  font-family: "Montserrat", sans-serif;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #ffffff;

  font-size: 15px;
  font-weight: bold;
}
.delivImgWrapper {
  width: 100%;
  margin: 20px auto;
  height: 255px;
  display: flex;
  align-items: center;
}
.delivImg {
  width: 100%;
  margin: 20px auto;
}
.checkboxWrapper {
  width: 130px;
  height: 20px;
  display: flex;
  justify-content: space-between;
}
.checkboxWrapper > label {
  font-size: 17px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
}
.checkboxWrapper > label:hover,
.checkboxWrapper > label > input:hover {
  cursor: pointer;
}
.checkboxWrapper > label > input {
  margin-right: 5px;
}
@media (min-width: 768px) {
  .deliveryContainer {
    max-width: 900px;
  }
  .priceInput {
    display: block;
  }
  .departWrapper {
    max-width: 900px;
    min-height: 450px;
  }
  .delivImg {
    height: 200px;
    width: auto;
  }
  .priceContainer {
    max-width: 900px;
  }
  .priceWrapper {
    max-width: 600px;
  }
  .mapImg {
    max-width: 530px;
  }
  .title {
    width: 100%;
    font-size: 25px;
    margin-bottom: 10px;
  }
  .delivImgWrapper {
    margin: 0px auto;
  }
  .checkboxWrapper {
    width: 170px;
  }
  .checkboxWrapper > label {
    font-size: 22px;
  }
  .checkboxWrapper > label > input {
    width: 16px;
    height: 16px;
  }
  .span {
    font-size: 16px;
    margin-top: 10px;
  }
  .totalDeliveryCost {
    font-size: 18px;
    text-decoration: underline;
  }
  .delivImg {
    margin: 0px auto;
  }
}
@media (min-width: 1200px) {
  .deliveryContainer {
    padding-top: 80px;
    max-width: 1400px;
  }
  .shadow {
    width: 1400px;
  }
  .departWrapper {
    max-width: 1400px;
    /* height: 730px; */
    display: block;
    text-align: center;
  }
  .mapImgWrapper {
    max-width: 800px;
  }
  .title {
    height: 60px;
    font-size: 35px;
  }
  .mapImg {
    max-width: 800px;
  }
  .checkboxWrapper {
    margin: 15px auto;
  }
  .priceInput {
    margin: 20px auto;
    font-size: 22px;
  }
  .checkboxWrapper {
    width: 260px;
  }
  .checkboxWrapper > label {
    font-size: 26px;
  }
  .checkboxWrapper > label > input {
    width: 20px;
    height: 20px;
  }
  .span {
    font-size: 20px;
    margin-top: 15px;
  }
  .totalDeliveryCost {
    font-size: 22px;
  }
}
