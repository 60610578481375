.form {
  display: flex;
  margin: 10px auto;
  box-shadow: -8px 8px 13px 1px rgba(0, 0, 0, 0.43);
  border-radius: 10px;
  justify-content: center;
  align-items: flex-start;
  outline: none;
  flex-wrap: wrap;
  max-width: 450px;
  width: 90%;
  height: 160px;
  background-color: #ffffff;
}
.input,
.inputPrice {
  height: 30px;
  border-radius: 5px;
  -moz-appearance: textfield;
  font-family: "Montserrat", sans-serif;
  width: 150px;
  padding: 5px 10px;
  outline: none;
  margin: 10px auto 0px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.submitBtn:hover {
  transition: 1s;
  cursor: pointer;
  background-color: #3f61e7;
}
.submitBtn {
  background-color: #e74911;
  width: 150px;
  height: 30px;
  display: block;
  margin: 0px auto;
  outline: none;
  border-radius: 8px;
  text-decoration: none;
  border: none;
  color: #ffffff;
  transition: 0.1s;
  box-shadow: -6px 6px 4px 0px rgba(0, 0, 0, 0.75);

  font-family: "Montserrat", sans-serif;
}
.checkboxWrapper {
  width: 130px;
  height: 20px;
  display: flex;
  margin: 10px auto;
  justify-content: space-between;
}
.checkboxWrapper > label {
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
}
.checkboxWrapper > label:hover,
.checkboxWrapper > label > input:hover {
  cursor: pointer;
}
.checkboxWrapper > label > input {
  margin-right: 5px;
}
.inputWrapper {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}
@media (min-width: 768px) {
  .input,
  .inputPrice {
    height: 40px;
    width: 250px;
    font-size: 20px;
  }

  .submitBtn {
    height: 40px;
    width: 250px;
    font-size: 20px;
  }
  .inputWrapper {
    padding: 10px 20px;
  }
  .checkboxWrapper {
    width: 200px;
    height: 30px;
  }
  .form {
    height: 210px;
    max-width: 650px;
  }
  .checkboxWrapper > label > input {
    width: 20px;
    height: 20px;
  }
  .checkboxWrapper > label {
    font-size: 22px;
    line-height: 28px;
  }
}
@media (min-width: 1200px) {
  .form {
    box-shadow: none;
    max-width: 700px;
    margin-top: 100px;
    height: 300px;
    padding: 20px;
  }
  .input,
  .inputPrice {
    height: 50px;
    width: 300px;
    font-size: 25px;
    padding: 0px 15px;
  }
  .submitBtn {
    height: 50px;
    width: 200px;
    font-size: 25px;
  }
  .checkboxWrapper > label > input {
    width: 20px;
    height: 20px;
  }
  .checkboxWrapper > label {
    font-size: 26px;
    line-height: 30px;
  }
  .checkboxWrapper {
    width: 250px;
    height: 40px;
  }
}
