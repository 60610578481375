.customTitle {
  font-size: 17px;
  font-weight: bold;
  align-self: center;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  line-height: 25px;
  color: #ffffff;
}
.span {
  width: 100%;
  font-size: 12px;
  font-weight: normal;
  line-height: 22px;
  font-family: "Montserrat", sans-serif;
  display: flex;
  color: #ffffff;
  justify-content: space-between;
}
.innerSpan {
  color: #ffffff;

  font-weight: bold;
}
.pseudo {
  width: 100%;
  height: 48px;
}
.customContainer {
  width: 100%;
  display: flex;
  max-width: 600px;

  margin: 00px auto;
  flex-wrap: wrap;
  justify-content: center;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  background-attachment: fixed;
  background-image: url(../../assets/img//bmwPanel.jpg);
}
.shadow {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  width: 100%;
  height: 100%;
}
.totalCustomCost {
  font-family: "Montserrat", sans-serif;
  line-height: 40px;
  color: #ffffff;

  display: flex;
  justify-content: space-between;
  width: 100%;

  font-size: 15px;
  font-weight: bold;
}
.engineTypeRadioWrapper {
  width: 85%;
  max-width: 260px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.engineTypeRadioWrapper > label {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 25%; */
  min-width: 90px;
  margin-top: 10px;
  font-size: 20px;
  line-height: 30px;
  position: relative;
}
.engineTypeRadioWrapper > label > input {
  width: 20px;
  margin: 0px 3px;
  height: 20px;
  display: none;
}
.checkmark {
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 10px 10px 0px 0px;
  border-bottom: none;
  transition: 600ms;
}
.checkmark:hover {
  cursor: pointer;
  background-color: #3e60e7;
}
.engineTypeRadioWrapper > label > input:checked ~ .checkmark {
  background-color: #233682;
  transition: 600ms;
  color: #ffffff;
}
.selectWrapper {
  width: 100%;
  display: flex;
}
.selectEngine {
  width: 30%;
  min-width: 150px;
  margin: 0px 5px 10px 0px;
}
.carYear {
  width: 100%;
  min-width: 160px;
  transition: width 3s;
}
@media (min-width: 480px) {
  .engineTypeRadioWrapper {
    width: 100%;
    min-width: 380px;
  }
}
@media (min-width: 768px) {
  .customContainer {
    max-width: 900px;
  }
  .span {
    max-width: 600px;
    margin: 10px auto;
    font-size: 16px;
  }
  .totalCustomCost {
    font-size: 18px;
    text-decoration: underline;
    max-width: 600px;
    margin: 0px auto;
  }
  .selectWrapper {
    max-width: 600px;
    margin: 0px auto;
  }
}
@media (min-width: 1200px) {
  .customContainer {
    max-width: 1400px;
  }
  .customTitle {
    font-size: 35px;
    margin: 25px auto;
  }
  .span {
    max-width: 600px;
    margin: 15px auto;
    font-size: 18px;
  }
  .totalCustomCost {
    font-size: 20px;
  }
  .selectWrapper {
    max-width: 600px;
  }
}
