.enter {
  opacity: 0;
  transform: scaleY(0);
}

.enterActive {
  opacity: 1;
  transform: scaleY(1);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.exit {
  opacity: 1;
  transform: scaleY(1);
}

.exitActive {
  opacity: 0;
  transform: scaleY(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
