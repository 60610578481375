.container {
  width: 100%;
  max-width: 450px;
  margin: 20px auto 60px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  text-align: center;
  height: 230px;
}
.img {
  width: 70px;
  height: 70px;
}
.title {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #ffffff;
}
.text {
  font-size: 16px;
  color: #ffffff;
}
.link {
  text-decoration: none;
  color: #ffffff;
}
@media (min-width: 768px) {
  .container {
    max-width: 650px;
    margin-top: 5px;
    height: 260px;
  }
  .img {
    width: 90px;
    height: 90px;
  }
  .title {
    font-size: 22px;
  }
  .text {
    font-size: 20px;
  }
}
