.container {
  width: 100%;
  max-width: 600px;
  margin: 0px auto;
  text-align: center;

  background-image: url(../../assets/img//usaflag.jpg);
  background-size: cover;
  background-position-x: 0px;
}
.link {
  width: 115px;
  height: 40px;
}
.linkImg {
  border-radius: 50%;
  height: 100%;
}
.list {
  list-style: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.iconsWrapper {
  width: 100%;
  max-width: 450px;
  position: relative;
  display: flex;
  margin: 15px auto;
  height: 40px;

  justify-content: space-between;
}
.item {
  margin-bottom: 40px;
  max-width: 100%;
  position: relative;
  color: #ffffff;
}
.arrow {
  position: absolute;
  width: 35px;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
}
.title {
  font-size: 20px;
  max-width: 300px;
  color: #ffffff;
  margin: 00px auto 20px;
}
.item {
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
}
.navLink {
  text-decoration: none;
  width: 100%;
  height: 100%;
  line-height: 35px;
  color: #ffffff;
  display: block;
}
.shadow {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 20px;
  text-decoration: none;
}
.navButton {
  background-color: #e74911;
  width: 180px;
  height: 35px;
  display: flex;
  margin: 20px auto;
  outline: none;
  border-radius: 8px;
  text-decoration: none;
  border: none;
  color: #ffffff;
  transition: 0.1s;
  position: relative;
  justify-content: center;
  align-items: center;
  box-shadow: -6px 6px 4px 0px rgba(0, 0, 0, 0.75);
}
.navButton:active {
  transition: 0.1s;
  transform: translate(-2px, 2px);
  box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.75);
}
.navButton:hover {
  cursor: pointer;
}
@media (min-width: 768px) {
  .container {
    max-width: 900px;
  }
  .title {
    font-size: 30px;
    width: 365px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1400px;
    height: 700px;
  }
  .title {
    font-size: 38px;
    margin: 30px auto 40px;
    max-width: 550px;
  }
  .item {
    max-width: 900px;
    font-size: 28px;
  }
  .iconsWrapper {
    max-width: 900px;
    height: 80px;
    width: 900px;
    margin: 45px auto;
    padding-right: 30px;
  }
  .link {
    width: 200px;
    margin: 0px 20px;
    height: 80px;
  }
  .link:nth-last-of-type(2n) {
    margin-right: -10px;
  }
  .navButton {
    width: 270px;
    height: 50px;
    line-height: 50px;

    margin-bottom: 20px;
    font-size: 18px;
  }
  .navLink {
    line-height: 50px;
  }
}
