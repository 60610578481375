.enter {
  opacity: 0;
  transform: translateY(-100%);
}

.enterActive {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;
}

.exit {
  opacity: 1;
  transform: translateY(0);
}

.exitActive {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;
}
