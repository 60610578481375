.container {
  min-height: calc(100vh - 60px);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  margin: 0px auto;
}
.wrapper {
  min-height: 92vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  background-image: url(../../assets//img//searchbackgr.jpg);
}
.infoText {
  font-size: 22px;
  margin: 50px 10px 280px;
  color: #fff;
  text-align: center;
}
.shadow {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.marginContainer {
  width: 100%;
  min-height: 70px;
}
@media (min-width: 768px) {
  .container {
    max-width: 900px;
  }
  .infoText {
    font-size: 35px;
  }
  .shadow {
    padding: 1px 30px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1400px;
    margin: 0px auto;
  }
}
