.menuButton,
.menuButton_active {
  display: block;
  transform-origin: 16px 11px;
  outline: 0;
  top: 3px;
  left: 0px;
  border: 0;
  padding: 10px;
  background: none;

  border-radius: 10px;
  z-index: 301;
  position: fixed;
}
.burgerSpan {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: block;
  width: 27px;
  height: 4px;
  margin-bottom: 7px;
  background-color: #fff;
}
.menuButton:focus {
  border: medium none #6fffb0;
  /* box-shadow: #6fffb0 0 0 2px 2px; */
  outline: 0;
}
.burgerSpan:nth-of-type(3) {
  margin-bottom: 1px;
}
.menuButton:hover > .burgerSpan:nth-of-type(1) {
  width: 18px;
}
.menuButton:hover > .burgerSpan:nth-of-type(2) {
  width: 27px;
}
.menuButton:hover > .burgerSpan:nth-of-type(3) {
  width: 10px;
}
.menuButton_active {
  padding: 10px 10px 10px 10px;
}
.menuButton_active > .burgerSpan:nth-of-type(1) {
  transform: rotate(45deg) translate(8px, 8px);
  width: 27px;
}
.menuButton_active > .burgerSpan:nth-of-type(2) {
  opacity: 0;
  pointer-events: none;
}
.menuButton_active > .burgerSpan:nth-of-type(3) {
  transform: rotate(-45deg) translate(8px, -8px);
  width: 27px;
}
@media (min-width: 768px) {
  .menuButton {
    display: none;
  }
}
