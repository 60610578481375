.outWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  max-width: 450px;
  margin: 10px auto;
}

.container {
  display: flex;
  margin: 10px auto;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
  box-shadow: -8px 8px 13px 1px rgba(0, 0, 0, 0.43);
  border-radius: 10px;
  padding: 30px 20px;
  background-color: #fff;
}

.span {
  width: 100%;
  font-size: 12px;
  font-weight: normal;
  line-height: 22px;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: space-between;
}
.innerSpan {
  font-weight: bold;
}
.wrapper {
  width: 100%;
  padding: 10px 20px;
}
.deliveryWrapper,
.customWrapper {
  width: 100%;
}
.title {
  font-size: 17px;
  font-weight: normal;
  align-self: center;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  line-height: 25px;
  font-weight: bold;
  margin-bottom: 15px;
}
.totalDeliveryCost,
.totalCustomCost,
.totalCost {
  font-family: "Montserrat", sans-serif;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  font-size: 15px;
  font-weight: bold;
}
.totalCost {
  font-size: 20px;
}

@media (min-width: 768px) {
  .outWrapper {
    max-width: 650px;
  }
  .span {
    font-size: 20px;
    display: flex;
    padding-right: 5px;
    line-height: 30px;
  }
  .totalDeliveryCost,
  .title,
  .totalCustomCost {
    font-size: 23px;
  }
  .totalCost,
  .totalCost > .innerSpan {
    font-size: 29px;
    margin-top: 10px;
  }
  .innerSpan {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .outWrapper {
    max-width: 1400px;
    box-shadow: -8px 8px 13px 1px rgba(0, 0, 0, 0.43);
    border-radius: 10px;
  }
  .container {
    width: 50%;
    box-shadow: none;
  }
}
