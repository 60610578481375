.CallBackButton,
.CallBackSubmitButton {
  background-color: #e74911;
  width: 180px;
  height: 35px;
  display: block;
  margin: 0px auto;
  outline: none;
  border-radius: 8px;
  text-decoration: none;
  border: none;
  color: #ffffff;
  transition: 0.1s;
  box-shadow: -6px 6px 4px 0px rgba(0, 0, 0, 0.75);
}
.CallBackButton:active,
.CallBackSubmitButton:active {
  transition: 0.1s;
  transform: translate(-2px, 2px);
  box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.75);
}
.CallBackButton:hover,
.CallBackSubmitButton:hover {
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: #ffffff;

  padding: 0px 20px;
}
.formWrappe {
  width: 100%;
  max-width: 450px;
  margin: 40px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.input {
  height: 35px;
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  outline: none;
  -moz-appearance: textfield;
  font-family: "Montserrat", sans-serif;
  margin: 10px 0px;
  padding: 2px 8px;
}
.formWrappe > form > label {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  color: #000000;
}
.textArea {
  width: 100%;
  height: 150px;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  padding: 10px;
}
.title {
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  margin: 70px auto 40px;
  color: #000000;
}
.closeButton {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #ffffff;
  border: none;
  outline: none;
  background-image: url(../../assets/svg/close.svg);
}
.CallBackSubmitButton {
  position: relative;
  margin: 40px auto;
}

@media (min-width: 768px) {
  .overlay {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .shadow {
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 900;
    top: 0px;
    left: 0px;
    position: fixed;
  }
  .title {
    margin: 40px 40px 0px;
    font-size: 19px;
  }
  .container {
    width: 80vw;
    background-color: #fff;
    position: relative;
    max-width: 600px;
    max-height: 85vh;
    border-radius: 10px;
  }
  .formWrappe {
    z-index: 3000;
    margin: auto;
    padding: 20px;
    height: 80vh;
    max-height: 380px;
  }
  .closeButton {
    top: 5px;
    right: 5px;
  }
  .CallBackSubmitButton {
    margin-top: 10px;
  }
  .closeButton:hover {
    cursor: pointer;
  }
}
@media (min-width: 1200px) {
  .CallBackButton {
    width: 270px;
    height: 50px;

    font-size: 18px;
    margin: 30px auto 50px;
  }
}
