.container {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.image {
  width: 80%;
  height: auto;
  max-height: 90vh;
  object-fit: scale-down;
}
