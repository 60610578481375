.overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 300;
  width: 100vw;
  height: 100vh;
}
.shadow {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
}
.logo {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 50px;
}

.menuList {
  display: flex;
  margin: 0px auto 0px 0px;
  flex-direction: column;
  align-items: flex-start;
  background-color: #000000;
  max-width: 220px;
  padding-top: 60px;
}

.menuItem {
  list-style: none;
  position: relative;
  font-family: serif;
  color: #000000;
  z-index: 201;
  width: 100%;
  min-height: 45px;

  padding-left: 10px;
  display: flex;
  align-items: center;
}
.menuItem :nth-last-of-type() {
  margin-top: 7px;
}
.menuItem > a {
  text-decoration: none;
  color: #ffffff;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.menuItem::after {
  content: "";
  left: 0px;
  width: 100%;
  position: absolute;
  background-color: #2c2c2e;
  z-index: -1;
  border-radius: 1px;
  top: 0px;
  height: 1px;
}
.shadowLink {
  max-width: 180px;
  width: 100%;
}
.icon {
  width: 15px;
  height: 15px;

  margin-right: 8px;
}
