.footerContainer {
  width: 100%;
  margin: 0px auto;
  height: 295px;
  background-color: #000000;
  display: flex;
  align-items: center;
  position: relative;
}
.imgWrapper {
  display: flex;
  width: 100%;
  max-width: 600px;
  position: relative;
  margin: 0px auto;
  justify-content: space-between;
}
.leftImg,
.rightImg {
  width: 30%;
}
.innerWraper {
  position: absolute;
  align-items: center;

  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.title {
  font-size: 18px;
  color: #ffffff;
}
.link {
  font-size: 16px;
  width: 30%;
  color: #ffffff;
}
.linkAbs {
  position: absolute;
  top: 30px;
  font-size: 16px;
  width: 30%;
  color: #ffffff;
}

.contactsWrapper {
  width: 100%;
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  margin: 40px auto 10px;
}
.logoLink {
  width: 30px;
  height: 30px;
  z-index: 10;
  position: relative;
}
.miniLogo {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: relative;
}
.logoWrapper {
  position: relative;
  margin-top: 15px;

  display: flex;
  width: 30%;
  justify-content: space-around;
  max-width: 150px;
}
.logoLink::before {
  top: -6px;
  content: "";
  border: 2px solid white;
  border-radius: 2px;
  border-radius: 10px;
  width: 42px;
  position: absolute;
  height: 38px;
  left: -7px;
  z-index: 1;
}
.logoLink::after {
  content: "";
  background-color: black;
  border: 3px solid black;
  position: absolute;
  transform: rotatez(35deg);
  height: 54px;
  left: 13px;
  z-index: 1;
  top: -15px;
}
.scheduleWrapper {
  margin-top: 20px;
}
.scheduleWrapper > p {
  color: #ffffff;
  margin-top: 5px;
}
@media (min-width: 600px) {
  .contactsWrapper {
    max-width: 550px;
  }
  .linkAbs {
    position: relative;
    top: 0px;
  }
  .logoWrapper {
    max-width: 200px;
  }
}
@media (min-width: 768px) {
  .footerContainer {
    max-width: 900px;
  }
}
@media (min-width: 1200px) {
  .footerContainer {
    max-width: 1400px;
  }
}
